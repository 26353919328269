<template>
<div>
    <h3>7、企业出售无形资产发生的净损失，应当计入（　）。</h3> </div>
</template>
<script>
export default {
data(){
return{
}
},
created(){
},
computed:{
},
methods:{
},
}
</script>
<style scoped>
</style>

<template>
<div>
    <h3>3、不同形态的资产的流动性不同，其获利能力也就不同，下面说法中正确的是（　）。</h3>A、流动性越高，其获利能力可能越高 <br><br>B、流动性越高，其获利能力可能越低 <br><br>C、流动性越低，其获利能力可能越低 <br><br>D、资产的获利能力与流动性成正比 </div>
</template>
<script>
export default {
data(){
return{
}
},
created(){
},
computed:{
},
methods:{
},
}
</script>
<style scoped>
</style>

<template>
  <div class="box">
    <!-- 题目 -->
    <div
      style="
        position: absolute;
        width: 200px;
        height: 70px;
        margin-left: 630px;
        margin-top: 20px;
      "
    >
      <img
        style="width: 200px; height: 70px"
        src="./assets/pc/tip.svg"
        alt=""
      />
    </div>
    <!-- 中间模版 -->
    <div
      style="
        position: absolute;
        margin-left: 270px;
        height: 500px;
        width: 600px;
      "
    >
      <ti
        :is="ti"
        style="
          position: absolute;
          margin-top: 200px;
          margin-left: 300px;
          color: white;
        "
      ></ti>
      <img
        style="height: 500px; width: 900px; margin-top: 100px; z-index: -1"
        src="./assets/pc/infomation.png"
        alt=""
      />
    </div>
    <div>
      
      <img
        style="
          position: absolute;
          margin-top: 100px;
          margin-left: 1150px;
          width: 100px;
          height: 100px;
          /* z-index: -1; */
        "
        src="./assets/pc/time.png"
        alt=""
      />
      <div style="position: absolute; width: 100px;height: 100px;color: white;  margin-top: 100px;
          margin-left: 1150px;text-align: center;line-height: 100px;font-size:30px;">{{shu}}</div>
    </div>
    <!-- 按钮-->

    <div
      style="
        position: absolute;
        /* outline: 1px solid red; */
        width: 720px;
        height: 100px;
        margin-top: 540px;
        margin-left: 420px;
        display: flex;
        justify-content: space-evenly;
      "
    >
      <div style="width: 200px; height: 100px">
        <img src="./assets/pc/listname.png" alt="" />
      </div>
      <div style=" width: 200px; height: 100px">
        <img  @click="$event=>ck($event)" v-if="num==0" src="./assets/pc/start.svg" alt="" />
        <img :class="{'box2':boxx}" @click="$event=>ck($event)" v-if="num>0 && num<12"  src="./assets/pc/answer.svg" alt="" />
        <img v-if="num==12" src="./assets/pc/start.svg" alt="" />
      </div>
      <div style="width: 200px; height: 100px">
        <img  @click="$event=>ck2()"  :class="{'box2':boxx}" src="./assets/pc/answer.png" alt="" />
      </div>
    </div>
    <!-- 答案 -->
    <div @click="$event=>ck2()"  :class="{'box3':boxx1}" style="  position: absolute;  width: 1400px; height: 700px; background-color:rgba(31, 9, 9, 0.767);">
      <div style=" width: 1000px;height: 500px; margin: auto;margin-top:100px;">
        <img style="position: absolute; width: 1000px;height: 500px;" src="./assets/pc/mask.png" alt="">
        <img style="position: absolute; width: 200px; margin-left: 400px; "  src="./assets/pc/answer.png" alt="">
        <div style="position: absolute;text-align: center;color: white; width: 200px;height: 200px;margin-left: 400px;margin-top: 200px;">
           <h1>{{ shu2 }}</h1>
        </div>
        
      </div>
      
    </div>
    <!-- 会记急转弯 -->
    <div style="margin-left: 100px">
      <img
        style="width: 100px; height: 400px"
        src="./assets/pc/anther.png"
        alt=""
      />
    </div>
    <!-- 二维码 -->
    <div style="margin-left: 50px">
      <img src='./assets/student.png' alt="" />
    </div>
  </div>
</template>
<script>
import ti1 from "./components/会记急转弯/ti1.vue";
import ti2 from "./components/会记急转弯/ti2.vue";
import ti3 from "./components/会记急转弯/ti3.vue";
import ti4 from "./components/会记急转弯/ti4.vue";
import ti5 from "./components/会记急转弯/ti5.vue";
import ti6 from "./components/会记急转弯/ti6.vue";
import ti7 from "./components/会记急转弯/ti7.vue";
import ti8 from "./components/会记急转弯/ti8.vue";
import ti9 from "./components/会记急转弯/ti9.vue";
import ti10 from "./components/会记急转弯/ti10.vue";
import ti11 from "./components/会记急转弯/ti11.vue";
import ti12 from "./components/会记急转弯/ti12.vue";
export default {
  data() {
    return {
      timer:'',
      ti:'',
      shu:0,
      ttt:0,
      boxx:'',
      boxx1:true,
      num:0,
      shu2:'',
      tidaan:['C','A','B','D','CDE','在建工程','营业外支出','管理费用','职工教育经费','子女教育','住房租金','加计抵减'],
      tiku: ['ti1', 'ti2', 'ti3', 'ti4', 'ti5', 'ti6', 'ti7', 'ti8', 'ti9', 'ti10','ti11','ti12'],
    };
  },
  components: {
    ti1,
    ti2,
    ti3,
    ti4,
    ti5,
    ti6,
    ti7,
    ti8,
    ti9,
    ti10,
    ti11,
    ti12,
  },
  created() {
  },
  computed: {},
  methods: {
    ck2(){
      this.boxx1=!this.boxx1
    },
    ck1(e){
      this.shu=10
      this.thimer=setInterval(()=>{
        console.log(this.shu);
        if(this.shu==0){
          clearInterval(this.thimer)
          this.boxx=false
        }else{
          this.shu-=1
          
          // e.target.pointerEvents = ''
        }
      },1000)
    },
    ck(e){
      // e.target.style. pointerEvents = 'none'
      this.boxx=true
      this.ck1(e)
      
      if(this.num<12){
        this.ti=this.tiku[this.num]
        this.shu2=this.tidaan[this.num]
        this.num+=1
        
      }
    }
  },
};
</script>
<style scoped>
.box {
  height: 700px;
  width: 1400px;
  background-image: url("./assets/pc/bc.jpg");
  background-size: 100% 100%;
}
.box2{
  pointer-events: none;
}
.box3{
  display: none;
}

</style>


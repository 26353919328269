<template>
<div>
    <h3>6 、外购资产，必须先通过（　）进行核算，然后再进行转固处理。</h3>
     </div>
</template>
<script>
export default {
data(){
return{
}
},
created(){
},
computed:{
},
methods:{
},
}
</script>
<style scoped>
</style>

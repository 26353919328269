<template>
<div>
    <h3>10、一子每月享一千，多个子女皆可享。（猜一个个税专项附加扣除项目）</h3> </div>
</template>
<script>
export default {
data(){
return{
}
},
created(){
},
computed:{
},
methods:{
},
}
</script>
<style scoped>
</style>

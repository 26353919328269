<template>
<div>
    <h3>8、企业对未使用、不需用的固定资产也应计提折旧，计提的折旧计入（　）。</h3> </div>
</template>
<script>
export default {
data(){
return{
}
},
created(){
},
computed:{
},
methods:{
},
}
</script>
<style scoped>
</style>

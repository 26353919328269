<template>
<div>
    <h3>9、工资薪金百分八，超过部分可结转。（猜一个政策）</h3> </div>
</template>
<script>
export default {
data(){
return{
}
},
created(){
},
computed:{
},
methods:{
},
}
</script>
<style scoped>
</style>

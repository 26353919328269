<template>
<div>
    <h3>11、交完房租不吃土，在沪一月一千五。（猜一个税专项附加扣除项目）</h3> </div>
</template>
<script>
export default {
data(){
return{
}
},
created(){
},
computed:{
},
methods:{
},
}
</script>
<style scoped>
</style>

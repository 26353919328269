import Vue from 'vue'
// 引入app作为全局最大的父组件
import App from './App.vue'
 
Vue.config.productionTip = false
// 全局自定义指令
Vue.directive('focus', (e) => {
  e.style.backgroundColor = 'aquamarine'
  e.style.color = 'red'
  e.style.fontSize = '100px'

})
new Vue({
  render: h => h(App),
}).$mount('#app')

<template>
<div>
    <h3>1、资产、负债和所有者权益是（）。</h3> A、表示企业经营成果的会计要素 <br></br> B、表示企业经营状况的会计要素<br></br> &nbspC、表示企业财务状况的会计要素 <br></br> D、表示企业现金流量的会计要素
 </div>
</template>
<script>
export default {
data(){
return{
}
},
created(){
},
computed:{
},
methods:{
},
}
</script>
<style scoped>
</style>

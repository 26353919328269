<template>
<div>
    <h3>2、下列各项中引起资产和权益同时增加的经济业务是（　）。</h3> A、购入材料，货款未付  <br></br> B、以银行存款支付采购办公用品款<br><br>&nbspC、以银行存款偿还银行借款<br><br> D、用银行存款购入机器一台 </div>
</template>
<script>
export default {
data(){
return{
}
},
created(){
},
computed:{
},
methods:{
},
}
</script>
<style scoped>
</style>

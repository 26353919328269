<template>
<div>
    <h3>4、管理会计的服务对象侧重于（　）。</h3>A、股东 <br><br>B、外部集团<br></br>C、债权人<br></br>D、企业内部的经营管理 </div>
</template>
<script>
export default {
data(){
return{
}
},
created(){
},
computed:{
},
methods:{
},
}
</script>
<style scoped>
</style>

<template>
<div>
    <h3>5、下列不需要进行会计处理的业务有（　）。<br></h3>A、用盈余公积转增资本<br><br>B、用资本公积转增资本<br><br>C、用税前利润补亏<br><br>D、用税后利润补亏<br><br>E、发放股票股利 </div>
</template>
<script>
export default {
data(){
return{
}
},
created(){
},
computed:{
},
methods:{
},
}
</script>
<style scoped>
</style>
